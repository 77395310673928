import React, {useRef, useState, useEffect} from 'react';
import NavBar from '../navbar';
import productUtils from '../../utils/product/product-utils';
import defaultLogo from '../../assets/img/default-logo.png';
import {setNewPassword} from '../../utils/users/users';
import authUtils from '../../utils/auth-provider/authProvider';
import Title from '../title-tile/titleTileComponent';

const SetNewPassword = (props) => {
    const [logoURL, setLogoURL] = useState(defaultLogo);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [success, setSuccess] = useState(false);

    const passwordInput = useRef();
    const repeatPasswordInput = useRef();

    useEffect(() => {
        async function getBusinessData () {
            const config = await productUtils.getBusinessConfig();
            const userData = await authUtils.getUserInfo();
            setUser(userData.user);
            return config;
        }
        getBusinessData().then(config => {
            if (config.success && config.data.logoURL) {
                setLogoURL(config.data.logoURL);
            }
        });
    }, []);

    const validatePasswords = () => {
        if (passwordInput.current.value === repeatPasswordInput.current.value) {
            setError(null);
            return true;
        } else {
            setError('Las passwords no coinciden');
            return false;
        }
    };

    const continueToMenu = (e) => {
        e.preventDefault();
        const password = passwordInput.current.value;

        if (validatePasswords() && !error) {
            setNewPassword({id: user._id, password }).then(res => {
                if (res.success) {
                    setSuccess(true);
                    setTimeout(() => {
                        authUtils.logout();
                        props.history.push('/login');
                    }, 3 * 1000);
                }
            });
        }
    };

return (
    <div>
        <NavBar></NavBar>
        <div className="auth-wrapper bg-light">
            <div className="auth-inner">
                <form>
                    <img className="img-fluid padding-middle" src={logoURL} alt="Logo"></img>
                    <div className="form-group">
                        <label>Nueva Contraseña</label>
                        <input ref={passwordInput} type="password" className="form-control" placeholder="Password" disabled={success}/>
                    </div>

                    <div className="form-group">
                        <label>Repita Contraseña</label>
                        <input ref={repeatPasswordInput} type="password" className="form-control" placeholder="Repeat Password" disabled={success}/>
                    </div>

                    <button type="submit" className="btn btn-dark btn-block" onClick={continueToMenu} disabled={success}>Continuar</button>
                    <p className="forgot-password text-center">
                        ¿Ya estas registrado? <a href="/login"> Ir a Login</a>
                    </p>
                    <p className="error-login text-center">{error}</p>
                </form>
                <Title
                    message='La contraseña se ha cambiado de manera exitosa!'
                    theme='success'
                    hide={!success}
                ></Title>
            </div>
        </div>
    </div>);
};

export default SetNewPassword;
