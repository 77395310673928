import React, {useEffect, useState} from 'react';
import NavBar from '../navbar';
import defaultLogo from '../../assets/img/default-logo.png';
import Title from '../title-tile/titleTileComponent';
import {setNeedsPasswordReset } from '../../utils/users/users';

const SetPasswordReset = (props) => {
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        async function setNeedReset() {
            const user = props.location && props.location.search && props.location.search.split('?')[1];
            const reset = await setNeedsPasswordReset(user);
            return reset;
        }
        setNeedReset().then(res => {
            if(res.success) {
                setSuccess(true);
            }
        })
    },[props.location]);

    return (
        <div>
            <NavBar></NavBar>
            <div className="auth-wrapper bg-light">
                <div className="auth-inner">
                    <img className="img-fluid padding-middle" src={defaultLogo} alt="Logo"></img>
                    <Title
                        message='La contraseña se blanqueo de manera exitosa!'
                        theme='success'
                        hide={!success}
                    ></Title>
                </div>
            </div>
        </div>);
};

export default SetPasswordReset;
