import React, { useRef, useState, useEffect } from 'react';
import defaultLogo from '../../assets/img/default-logo.png';
import './loginComponent.css';
import auth from '../../utils/auth-provider/authProvider';
import NavBar from '../navbar';
import productUtils from '../../utils/product/product-utils';

const Login = (props) => {
  const [error, setError] = useState(null);
  const [logoURL, setLogoURL] = useState(defaultLogo);

  const emailInput = useRef();
  const pswInput = useRef();

  useEffect(() => {
    async function getBusinessData() {
      const config = await productUtils.getBusinessConfig();
      return config;
    }
    getBusinessData().then((config) => {
      if (config.success && config.data.logoURL) {
        setLogoURL(config.data.logoURL);
      }
    });
  }, []);

  const onSubmitLogin = (e) => {
    e.preventDefault();
    const currentFormValue = {
      username: emailInput.current.value,
      password: pswInput.current.value,
    };

    // Authentication against passport
    auth
      .authenticate(currentFormValue)
      .then((data) => {
        if (data.success) {
          props.history.push('/menu');
        } else {
          setError('Password o email son incorrectos');
        }
      })
      .catch((err) => {
        console.error(err);
        setError('Ups!...Hay un problema...Pruebe nuevamente mas tarde...');
      });
  };

  return (
    <div>
      <NavBar></NavBar>
      <div className="auth-wrapper bg-light">
        <div className="auth-inner">
          <form>
            <img
              className="img-fluid padding-middle"
              src={logoURL}
              alt="Logo"
            ></img>
            <div className="form-group">
              <label>Email</label>
              <input
                ref={emailInput}
                type="email"
                className="form-control"
                placeholder="email@example.com"
              />
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                ref={pswInput}
                type="password"
                className="form-control"
                placeholder="Password"
              />
            </div>

            <button
              type="submit"
              className="btn btn-dark btn-block"
              onClick={onSubmitLogin}
            >
              Log In
            </button>
            <p className="forgot-password text-center">
              ¿Aun no estas registrado? <a href="/register"> Ir a Registro</a>
            </p>
            <p className="forgot-password text-center">
              ¿Olvidaste tu contraseña? <a href="/recovery"> Ir a Recuperar</a>
            </p>
            <p className="error-login text-center">{error}</p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
